.fc-event.fc-event-inactive {
    opacity: 0.6;
}

.fc-event.fc-event-expired {
    pointer-events: none;
    opacity: 0.4;
}

.fc-event {
    cursor: pointer;
}

.fc-event {
    padding: 0.5em 1em;
}